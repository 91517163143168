import React from 'react';

const GoogleMap = () => {
    return (
        <>
            <iframe src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJP9SLGhfjf0cRPQjsDCaAAtg&key=AIzaSyAfzUnxjRMAjI8s0yfK6YHiu0AKsfeH9FE" width="100%" height="450" allowFullScreen="" loading="lazy"></iframe>
        </>
    )
}

export default GoogleMap;  